import * as actionTypes from '../utils/actionTypes';

export const onChangeRotation = (rotation) => ({
  type: actionTypes.CHANGE_ROTATION,
  rotation: rotation
});

export const onStartNinmu = () => ({
  type: actionTypes.START_NINMU,
});


export const onChangeArg = (ninjaIndex, lineIndex, argIndex, value) => ({
  type: actionTypes.CHANGE_ARG,
  ninjaIndex,
  lineIndex,
  argIndex,
  value
});

export const onAddLine= (ninjaIndex)=>({
  type: actionTypes.ADD_LINE,
  ninjaIndex
});

export const onAddNinja= ()=>({
  type: actionTypes.ADD_NINJA,
});

export const onFireNinja= (ninjaIndex)=>({
  type: actionTypes.FIRE_NINJA,
  ninjaIndex
});


export const onDeleteLine= (ninjaIndex, lineIndex)=>({
  type: actionTypes.DELETE_LINE,
  ninjaIndex,
  lineIndex
});


export const onChangeColor = (ninjaIndex, colorIndex)=>({
  type: actionTypes.CHANGE_COLOR,
  ninjaIndex,
  colorIndex
});


export const onMoveNinja = (ninjaIndex, move)=>({
  type: actionTypes.MOVE_NINJA,
  ninjaIndex,
  move
});


export const onMoveLine= (ninjaIndex, lineIndex, move)=>({
  type: actionTypes.MOVE_LINE,
  ninjaIndex,
  lineIndex,
  move
});
