
import lib,{arrayToColor} from "./p5lib.js";

export default function sketch (p) {


  let r = 0;
  let vr = 0;
  let ninjas = [];

  p.setup = function () {
    console.log("spin.setup")
    p.createCanvas(300, 300);
  };

  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
    console.log("spin.change")
    //何かが変わったら即反映
    ninjas = props.ninjas;
    vr = props.rotation;
  };

  p.draw = ()=>{
    console.log("spin.draw")
    p.background(255);

    p.push();

      p.translate(p.width/2 + 70 *  p.sin(r*0.5)  , p.height/2 + 70 *  p.cos(r*0.6) );
      //p.translate(p.width/2   , p.height/2 );
      p.rotate(r);
      shuriken();
    p.pop();
    r += vr*0.01;
  }

  function shuriken(){
    p.push();
      p.scale(10,-10);
      brade();
      p.rotate(p.PI/2);
      brade();
      p.rotate(p.PI/2);
      brade();
      p.rotate(p.PI/2);
      brade();

    p.pop();
    p.fill(255);
    p.ellipse(0,0,20,20);
  }

  function brade(){
    
    runNinja(ninjas)
  }


  function runNinja(ninjas){

    ninjas.forEach((ninja)=>{
      
      if(ninja.type == "small_ninja"){
        const program = ninja.program;

        p.beginShape();
        p.fill(arrayToColor(ninja.color));
        p.noStroke();

        program.forEach((line)=>{
          if(line.method == "vertex"){
            p.vertex(...line.args);
          }
        })

        p.endShape();
      }

    })
    
  }




};







