export const CHANGE_ROTATION = 'CHANGE_ROTATION ';
export const START_NINMU = 'START_NINMU ';
export const CHANGE_ARG = 'CHANGE_ARG ';
export const ADD_LINE = 'ADD_LINE ';
export const ADD_NINJA = 'ADD_NINJA ';
export const FIRE_NINJA = 'FIRE_NINJA ';
export const DELETE_LINE = 'DELETE_LINE ';
export const CHANGE_COLOR = 'CHANGE_COLOR ';
export const MOVE_NINJA= 'MOVE_NINJA';
export const MOVE_LINE= 'MOVE_LINE';



