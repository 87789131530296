import * as actionTypes from '../utils/actionTypes';
import { combineReducers } from 'redux';

import initialAppState  from '../initialAppState.json';


const rotation = (state = initialAppState.rotation, action) => {
  switch(action.type){
    case actionTypes.CHANGE_ROTATION:
      return action.rotation;
    break;

    default:
      return state;
    break;
  }
}

const flash = (state = initialAppState.flash, action) => {
  switch(action.type){
    case actionTypes.START_NINMU:
      return state + 1;
    break;
    default:
      return state;
    break;
  }
}

const ninjas = (state = initialAppState.ninjas, action) => {
  const ninjas = state;
  switch(action.type) {
    case actionTypes.CHANGE_ARG:
      console.log("action.ninjaIndex, action.lineIndex, action.argIndex, action.value");
      console.log(action.ninjaIndex, action.lineIndex, action.argIndex, action.value)
      return ninjas.map((ninja,index)=>{
        if(action.ninjaIndex == index){
          return {
            ...ninja,
            program: ninja.program.map((line,index)=>{
              if(action.lineIndex == index){
                return {
                  ...line,
                  args: line.args.map((arg,index)=>{
                    if(action.argIndex == index ){
                      return action.value;
                    }
                    else{
                      return arg;
                    }
                  })
                }
              }
              else{
                return line;
              }
            })
          }
        }
        else{
          return ninja;
        }
      })
    break;
    case actionTypes.ADD_LINE:
      console.log("lineAdded");
      return [
        ...ninjas.map((ninja,index)=>{
          if(index === action.ninjaIndex){
            return Object.assign({},ninja, 
              {program:[
                ...ninja.program,
                {
                  method: "vertex",
                  args: [0,0]
                },
              ]});
          }
          else{
            return ninja; //変更しない
          }
        })
      ]
    break;

    case actionTypes.ADD_NINJA:
      console.log("ninjaAdded");
      return [
        ...ninjas,
        {
          type: "small_ninja",
          color: [0,0,0],
          program: [
            {
              method: "vertex",
              args: [0,0],
            },
            {
              method: "vertex",
              args: [0,5],
            },
            {
              method: "vertex",
              args: [8,8],
            },
            {
              method: "vertex",
              args: [5,0],
            },
          ]
        }
      ]
    break;
    case actionTypes.FIRE_NINJA:
      console.log("ninjaFired");
      const copy = ninjas.slice();
      copy.splice(action.ninjaIndex, 1);
      return copy;
    break;

    case actionTypes.DELETE_LINE:
      console.log("lineDeleted");

      const newProgram = ninjas[action.ninjaIndex].program.slice();
      newProgram.splice(action.lineIndex, 1);

      return ninjas.map((ninja,index)=>{
        if(action.ninjaIndex == index){
          return {
            ...ninja,
            program: newProgram
          }
        }
        else{
          return ninja;
        }
      })

    break;

    case actionTypes.CHANGE_COLOR:
      return ninjas.map((ninja,index)=>{
        if(action.ninjaIndex == index){

          return {
            ...ninja,
            color: ninja.color.map((val,index) => action.colorIndex == index ? (val == 1 ? 0: 1) : val)
          }
        }
        else{
          return ninja;
        }
      })
    break;

    case actionTypes.MOVE_NINJA:
      const copiedNinja = ninjas.slice();

      const deleted = copiedNinja.splice(action.ninjaIndex, 1);
      const nextPos = action.ninjaIndex + action.move ;

      if(nextPos < 0 || nextPos >= ninjas.length){
        return ninjas;
      }

      copiedNinja.splice(nextPos, 0, ...deleted);

      return copiedNinja;
      

      
    break;

    case actionTypes.MOVE_LINE:


      return ninjas.map((ninja,index)=>{
        if(action.ninjaIndex == index){

          const copiedProgram = ninja.program.slice();
          const deleted = copiedProgram.splice(action.lineIndex, 1);
          const nextPos = action.lineIndex + action.move ;

          if(nextPos < 0 || nextPos >= ninja.program.length){
            return ninja;
          }

          copiedProgram.splice(nextPos, 0, ...deleted);

          return {
            ...ninja,
            program: copiedProgram
          }
        }
        else{
          return ninja;
        }
      })
      
    break;

    default:
      return ninjas;
    break;
  }
};

export default combineReducers({
  rotation,
  flash,
  ninjas,
});
