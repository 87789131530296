
import lib,{arrayToColor} from "./p5lib.js";


export default function sketch (p, second) {
  console.log("new sketch")
  console.log(p);

  class RunningNinja{
    constructor(ninjaObject){
      console.log("Ninja Constructed");
      this.color = arrayToColor(ninjaObject.color);
      this.program = ninjaObject.program;
      this.currentLine = 0;
      this.t = 0.0;

      this.frame = 0;

      this.ninjaImages = {
        up:[
          p.loadImage("./img/up-0.png"),
          p.loadImage("./img/up-1.png"),
        ],

        climb:[
          p.loadImage("./img/climb-0.png"),
          p.loadImage("./img/climb-1.png"),
        ],

        run:[
          p.loadImage("./img/run-0.png"),
          p.loadImage("./img/run-1.png"),
        ],

        slide:[
          p.loadImage("./img/slide-0.png"),
          p.loadImage("./img/slide-1.png"),
        ],

        fall:[
          p.loadImage("./img/fall-0.png"),
          p.loadImage("./img/fall-1.png"),
        ],

        land:[
          p.loadImage("./img/land-0.png"),
          p.loadImage("./img/land-1.png"),
        ],
      }
    }

    init(){
      this.currentLine = 1;
      this.t = 0.0;

    }

    isStopped(){
        return this.currentLine+1 == this.program.length && this.t == 1;
    }


    draw(){
      const prevPoint = this.program[this.currentLine].args;
      const nextPoint = this.program[(this.currentLine+1) % this.program.length].args;


      const x = 
        prevPoint[0]*(1-this.t) + nextPoint[0]*(this.t);

      const y = 
        prevPoint[1]*(1-this.t) + nextPoint[1]*(this.t);

      p.push()
        {
          if(this.isStopped()){
            p.fill(this.color);
            p.noStroke();
            p.beginShape();
          }
          else{
            p.noFill();
            p.stroke(this.color);
            p.strokeWeight(0.05);
            p.beginShape();
          }

          let i = 0;

          this.program.forEach((line, index)=>{
            if(this.currentLine >= index){
              if(line.method == "vertex"){
                p.vertex(...line.args);
              }
            }
            else if(this.currentLine+1 == index){
              p.vertex(x,y);
            }
            else{
              p.vertex(x,y);
            }
          })

          if(this.currentLine+1 < this.program.length){

            p.endShape();
          }
          else if(this.currentLine+1 == this.program.length){

            p.vertex(x,y);
            p.endShape();
          }
          else{

            p.endShape(p.CLOSE);
          }

        }

        p.fill(this.color);
        p.noStroke();
        
        p.imageMode(p.CENTER);

        p.push()
          p.translate(x,y);

          const dx = nextPoint[0] - prevPoint[0];
          const dy = nextPoint[1] - prevPoint[1];

          const gradient = dy/p.abs(dx);

          let direction = "";
          if(gradient > p.tan(p.PI/2 - p.PI/6)){
            direction = "up";
          }
          else if(gradient >= p.tan(p.PI/4)){
            direction = "climb";
          }

          else if(gradient < - p.tan(p.PI/2 - p.PI/9)){
            direction = "fall";
          }
          else if(gradient < - p.tan(p.PI/6)){
            direction = "slide";
          }
          else{
            direction = "run";
          }

          if(dx > 0){
            p.scale(1,-1);
          }
          else{
            p.scale(-1,-1);
          }
          

          p.image(this.ninjaImages[direction][(this.frame % 40 < 20)? 0: 1],0,0,7,7);
        p.pop()


        if(this.currentLine < this.program.length){
          this.t += 0.01;
          this.frame ++;
        }

        if(this.t >= 1){
          if(this.currentLine+1 == this.program.length){
            this.t = 1;
          }
          else{
            this.t = 0;

          }

          if(this.currentLine+1 < this.program.length){
            this.currentLine = this.currentLine + 1 ;
          }
        }

      p.pop()
    }
  }



  let ninjas = [];
  let ninjaObjects = [];
  let action = "";

  let t = 0;

  let ninjaImage = null;

  p.setup = function () {
    p.createCanvas(300, 300);

    console.log("p.setup" );
  };

  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {

    console.log("p.myCustomRedrawAccordingToNewPropsHandler");
    if(action != props.action){
      action = props.action;
      ninjas = props.ninjas;

      ninjaObjects = ninjas.map((ninja, index)=>{

        const ninjaObject = new RunningNinja(ninja);

        return ninjaObject;
      });

      currentNinjaIndex = 0;
      t = 0;
    }
  };


  let currentNinjaIndex = 0;
  p.draw = ()=>{

    console.log("p.draw");
    /*
    console.log("ninjas");
    console.log(ninjas);
    console.log("ninjaObjects");
    console.log(ninjaObjects);
    console.log("action");
    console.log(action);
    console.log("t");
    console.log(t);
    console.log("ninjaImage");
    console.log(ninjaImage);
    */

    p.background(255);

    p.push()
      p.translate(10, p.height-10);
      p.scale(30, -30)

      p.stroke(0);
      p.strokeWeight(0.01);
      
      for(let i=0; i<=9;i++){
        p.line(i,0,i,9);
      }
      for(let i=0; i<=9;i++){
        p.line(0,i,9,i);
      }

      if(!ninjaObjects || ninjaObjects.length == 0){
        //まだ propが来てないから描画しない
        return ;
      }

      for( let ninjaIndex in ninjaObjects){
        if( ninjaIndex <= currentNinjaIndex ){
          const ninja = ninjaObjects[ninjaIndex];
          ninja.draw();
        }
      }

     

      if(ninjaObjects[currentNinjaIndex].isStopped() && currentNinjaIndex + 1 < ninjaObjects.length){
        currentNinjaIndex ++;
      }



      brade()
    p.pop()
      

    t+= 0.001;

  }


  function brade(){
    
    runNinja(ninjas)
  }


  function runNinja(ninjas){
    ninjas.forEach((ninja)=>{
    })
  }
};







