import React,{Component} from 'react';
import logo from './logo.svg';
import './App.css';


import NinjaNinmus from './NinjaNinmus';

import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketch.js';
import spinSketch from './spin-sketch.js';


import { createStore, bindActionCreators } from "redux";
import { Provider, connect  } from "react-redux";
import * as actionTypes from './utils/actionTypes';
import reducers from './reducers/index.js';

import * as actions from './actions';

import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";

const store = createStore(reducers);


function ShurikenPreview(props){
  return (
    <div className="e-screen">
      <P5Wrapper sketch={spinSketch} rotation={props.rotation} ninjas={props.ninjas}/>
      <input type="range" value={props.rotation} min="0"  max="10" step="1" onInput={(e)=>props.actions.onChangeRotation(e.target.value)} onChange={(e)=>props.actions.onChangeRotation(e.target.value)} />

      <Link to="/program">任務を変える</Link> | 
      <Link to="/program">完成！</Link>
    </div>
  );
}

class Container extends Component{
  render(){
    return (
    <Router>
      <div className="App c-page">
        <Switch>
          <Route exact path="/" render={()=>(
            <div className="e-screen">
              <ul>
                <li>
                  <Link to="/program">シュリケンをプログラミング！</Link>
                </li>
              </ul>
            </div>
          )} />

          <Route path="/program" render={()=>
            <>
              <div className="e-screen">
                <P5Wrapper sketch={sketch}  ninjas={this.props.ninjas} action={this.props.flash}/>
                <input type="button" value="任務を練習" onClick={this.props.actions.onStartNinmu} />
                <Link to="/preview">手裏剣にする！</Link>
              </div>
              <div className="e-controll">
                <NinjaNinmus ninjas={this.props.ninjas} />
              </div>
            </>
            } />
          <Route path="/preview" render={()=>
            <ShurikenPreview rotation={this.props.rotation} ninjas={this.props.ninjas} actions={this.props.actions}></ShurikenPreview>
            } />
        </Switch>
      </div>
    </Router>
    )
  }
}

const ConnectedContainer = connect(
  (state)=> state ,
  (dispatch)=>({
    actions: bindActionCreators(actions, dispatch),
  })
)(Container);

class App extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return (
      <Provider store={store}>
        <ConnectedContainer/>
      </Provider>
    );
  }
}


export default App;
