import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions';

import {arrayToColor} from "./p5lib.js";
import React,{Component} from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class NinjaNinmus extends Component{

  constructor(props){
    super(props);

  }

  render(){
    return(
      <Tabs selectedTabClassName={"m-selected"}>
        <div className="c-tabs-wrapper">
          <TabList className="c-tabs">

            {this.props.ninjas.map((ninja, ninjaIndex)=>
              <Tab className="e-tab"  key={ninjaIndex} style={{color:arrayToColor(ninja.color)}}>{ninja.color}忍</Tab>
            )}

            <li className="e-tab" onClick={()=>this.props.actions.onAddNinja()}>+</li>
          </TabList>
        </div>

        {this.props.ninjas.map((ninja, ninjaIndex)=>
          <TabPanel key={ninjaIndex}>
            <div className="c-ninjas">

              <div className="e-ninja" key={ninjaIndex}>
                <header className="e-header">
                  <button className="e-delete-button" onClick={()=>this.props.actions.onFireNinja(ninjaIndex)}>任務終了</button>
                  <h1 style={{fontSize:"18px"}}>{ninja.color}ニンジャ</h1>

                  <p>
                    <button onClick={()=>this.props.actions.onMoveNinja(ninjaIndex, -1)}>▲</button>
                    <button onClick={()=>this.props.actions.onMoveNinja(ninjaIndex, +1)}>▼</button>
                  </p>
                  
                  <div style={{display:"inline-block", width:"1em", height:"1em", background:arrayToColor(ninja.color)}}></div>
                  <label >
                    赤
                    <input type="checkbox" checked={ninja.color[0]} onChange={(e)=>this.props.actions.onChangeColor(ninjaIndex, 0)}/>
                  </label>

                  <label>
                    緑
                    <input type="checkbox" checked={ninja.color[1]} onChange={(e)=>this.props.actions.onChangeColor(ninjaIndex, 1)}/>
                  </label>

                  <label >
                    青
                    <input type="checkbox" checked={ninja.color[2]} onChange={(e)=>this.props.actions.onChangeColor(ninjaIndex, 2)}/>
                  </label>
                </header>



                <ul className="e-program">
                  {ninja.program.map((line,index)=>
                    <li className="e-line" key={index}>
                      <input 
                        type="number" 
                        value={line.args[0]} 
                        style={{width:"3em",textAlign:"center"}}
                        onChange={(e)=>{this.props.actions.onChangeArg(ninjaIndex, index, 0, e.target.value)}}
                        onFocus={(e)=>{e.target.select()}}
                        />,
                      <input 
                        type="number" 
                        value={line.args[1]} 
                        style={{width:"3em",textAlign:"center"}}
                        onChange={(e)=>{this.props.actions.onChangeArg(ninjaIndex, index, 1, e.target.value)}}
                        onFocus={(e)=>{e.target.select()}}
                        />
                      に進む
                      <button className="e-prev" onClick={()=>this.props.actions.onMoveLine(ninjaIndex , index, -1)}>▲</button>
                      <button className="e-next" onClick={()=>this.props.actions.onMoveLine(ninjaIndex , index, +1)}>▼</button>
                      <button className="e-delete-line" onClick={()=>this.props.actions.onDeleteLine(ninjaIndex , index)}>削除</button>
                    </li>
                  )}
                </ul>
                <button onClick={()=>this.props.actions.onAddLine(ninjaIndex)}>任務を追加</button>
              </div>

            </div>
          </TabPanel>

        )}
      </Tabs>
    )
  }
}

export default connect((state, ownProps)=>({
  ninjas: state.ninjas
}),(dispatch)=>({
  actions: bindActionCreators(actions, dispatch),
}))(NinjaNinmus);